var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"questions-table",attrs:{"id":"questions-table"}},[_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('h2',{staticClass:"mb-0"},[_vm._v("\n        "+_vm._s(_vm.lang.clustering.questionsTable.title[_vm.languageSelected])+"\n        "),(_vm.questionsLength)?_c('span',[_vm._v("("+_vm._s(_vm.questionsLength)+")")]):_vm._e()])])],1),_vm._v(" "),_c('div',{staticClass:"vx-row"},[_c('vs-col',{attrs:{"vs-w":"12"}},[_c('div',{staticClass:"pt-4 pb-base vs-con-loading__container"},[_c('vs-table',{key:_vm.tableKey,ref:"table",attrs:{"sst":true,"data":_vm.questionsData,"max-items":_vm.LIMIT,"stripe":"","no-data-text":_vm.lang.general.noDataText[_vm.languageSelected]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
return _vm._l((data),function(tr,indextr){return _c('vs-tr',{key:indextr,staticClass:"bg-white"},[_c('vs-td',[_vm._v(_vm._s(tr.input && tr.input[0]))]),_vm._v(" "),_c('vs-td',[(tr.messageTrainer && tr.messageTrainer.status)?_c('vs-chip',{attrs:{"color":_vm.getStatusColor(
                      tr.messageTrainer && tr.messageTrainer.status
                    )}},[_c('vs-avatar',{attrs:{"color":"transparent","icon":_vm.getStatusIcon(
                        tr.messageTrainer && tr.messageTrainer.status
                      ),"icon-pack":"feather"}}),_vm._v("\n                  "+_vm._s(_vm.getClusterStatus(
                      tr.messageTrainer && tr.messageTrainer.status
                    ))+"\n                ")],1):_vm._e()],1),_vm._v(" "),_c('vs-td',[_vm._v("\n                "+_vm._s(_vm.getBotName(tr.service))+"\n              ")]),_vm._v(" "),_c('vs-td',[_vm._v("\n                "+_vm._s(_vm.versionsById[tr.version])+"\n              ")]),_vm._v(" "),_c('vs-td',[_c('VersionFilter',{attrs:{"service-id":tr.service,"label":false},on:{"change":function($event){_vm.setNewVersion($event, tr)}}})],1),_vm._v(" "),_c('vs-td',[(tr.newVersion)?_c('IntentFilter',{key:tr._id,attrs:{"service-id":tr.service,"version-id":tr.newVersion,"label":false,"multiple":false,"defaultOptions":false},on:{"change":function($event){_vm.setNewIntent($event, tr)}}}):_vm._e()],1),_vm._v(" "),_c('vs-td',[_c('transition',{attrs:{"name":"fade"}},[(tr.enableTrain)?_c('vs-button',{key:("enable-" + (tr._id)),ref:("loadableButton-" + (tr._id)),refInFor:true,staticClass:"vs-con-loading__container",attrs:{"id":("loadableButton-" + (tr._id)),"size":"small"},on:{"click":function($event){_vm.train(tr)}}},[_vm._v("\n                    "+_vm._s(_vm.lang.clustering.questionsTable.train[_vm.languageSelected])+"\n                  ")]):_vm._e()],1)],1)],1)})}}])},[_c('template',{slot:"thead"},[_c('vs-th',[_vm._v("\n              "+_vm._s(_vm.lang.clustering.questionsTable.question[_vm.languageSelected])+"\n            ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n              "+_vm._s(_vm.lang.clustering.questionsTable.status[_vm.languageSelected])+"\n            ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n              Bot\n            ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n              "+_vm._s(_vm.lang.clustering.questionsTable.version[_vm.languageSelected])+"\n              "),_c('KonaHelpTooltip',{attrs:{"text":_vm.lang.clustering.questionsTable.versionTooltip[
                    _vm.languageSelected
                  ],"position":"top","icon":"AlertCircleIcon"}})],1),_vm._v(" "),_c('vs-th',[_vm._v("\n              "+_vm._s(_vm.lang.clustering.questionsTable.trainVersion[_vm.languageSelected])+"\n            ")]),_vm._v(" "),_c('vs-th',[_vm._v("\n              "+_vm._s(_vm.lang.clustering.questionsTable.intent[_vm.languageSelected])+"\n              "),_c('KonaHelpTooltip',{attrs:{"text":_vm.lang.clustering.questionsTable.intentTooltip[
                    _vm.languageSelected
                  ],"position":"top","icon":"AlertCircleIcon"}})],1),_vm._v(" "),_c('vs-th')],1)],2),_vm._v(" "),_c('vs-pagination',{staticClass:"mt-2 float-right",attrs:{"total":Math.ceil(_vm.questionsLength / _vm.LIMIT)},on:{"change":_vm.handleChangePage},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }