<template>
  <section class="questions-table" id="questions-table">
    <div class="vx-row">
      <vs-col vs-w="12">
        <h2 class="mb-0">
          {{ lang.clustering.questionsTable.title[languageSelected] }}
          <span v-if="questionsLength">({{ questionsLength }})</span>
        </h2>
      </vs-col>
    </div>

    <div class="vx-row">
      <vs-col vs-w="12">
        <div class="pt-4 pb-base vs-con-loading__container">
          <!-- :data="questionsData[currentPage]" -->
          <vs-table
            :key="tableKey"
            :sst="true"
            :data="questionsData"
            :max-items="LIMIT"
            ref="table"
            stripe
            :no-data-text="lang.general.noDataText[languageSelected]"
          >
            <template slot="thead">
              <vs-th>
                {{ lang.clustering.questionsTable.question[languageSelected] }}
              </vs-th>
              <vs-th>
                {{ lang.clustering.questionsTable.status[languageSelected] }}
              </vs-th>
              <vs-th>
                Bot
              </vs-th>
              <vs-th>
                {{ lang.clustering.questionsTable.version[languageSelected] }}
                <KonaHelpTooltip
                  :text="
                    lang.clustering.questionsTable.versionTooltip[
                      languageSelected
                    ]
                  "
                  position="top"
                  icon="AlertCircleIcon"
                />
              </vs-th>
              <vs-th>
                {{
                  lang.clustering.questionsTable.trainVersion[languageSelected]
                }}
              </vs-th>
              <vs-th>
                {{ lang.clustering.questionsTable.intent[languageSelected] }}
                <KonaHelpTooltip
                  :text="
                    lang.clustering.questionsTable.intentTooltip[
                      languageSelected
                    ]
                  "
                  position="top"
                  icon="AlertCircleIcon"
                />
              </vs-th>
              <vs-th></vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr
                :key="indextr"
                v-for="(tr, indextr) in data"
                class="bg-white"
              >
                <!-- question -->
                <vs-td>{{ tr.input && tr.input[0] }}</vs-td>

                <!-- status -->
                <vs-td>
                  <vs-chip
                    v-if="tr.messageTrainer && tr.messageTrainer.status"
                    :color="
                      getStatusColor(
                        tr.messageTrainer && tr.messageTrainer.status
                      )
                    "
                  >
                    <vs-avatar
                      color="transparent"
                      :icon="
                        getStatusIcon(
                          tr.messageTrainer && tr.messageTrainer.status
                        )
                      "
                      icon-pack="feather"
                    />
                    {{
                      getClusterStatus(
                        tr.messageTrainer && tr.messageTrainer.status
                      )
                    }}
                  </vs-chip>
                </vs-td>

                <!-- bot -->
                <vs-td>
                  {{ getBotName(tr.service) }}
                </vs-td>

                <!-- question bot version -->
                <vs-td>
                  {{ versionsById[tr.version] }}
                </vs-td>

                <!-- bot versions to train -->
                <vs-td>
                  <VersionFilter
                    :service-id="tr.service"
                    :label="false"
                    @change="setNewVersion($event, tr)"
                  />
                </vs-td>

                <!-- toolip with detected intent -->
                <!-- <vs-td>
                  <KonaHelpTooltip
                    v-if="isTrained(tr)"
                    :text="getDetectedIntent(tr)"
                    position="top"
                    icon="AlertCircleIcon"
                  />
                </vs-td> -->

                <!-- bot intents -->
                <vs-td>
                  <!-- v-if="(tr.messageTrainer && tr.messageTrainer.status === 'trained') || tr.newVersion" -->
                  <!-- :selected="getCurrentIntent(tr)" -->
                  <IntentFilter
                    v-if="tr.newVersion"
                    :key="tr._id"
                    :service-id="tr.service"
                    :version-id="tr.newVersion"
                    :label="false"
                    :multiple="false"
                    :defaultOptions="false"
                    @change="setNewIntent($event, tr)"
                  />
                </vs-td>

                <!-- actions -->
                <vs-td>
                  <transition name="fade">
                    <vs-button
                      v-if="tr.enableTrain"
                      :key="`enable-${tr._id}`"
                      :ref="`loadableButton-${tr._id}`"
                      :id="`loadableButton-${tr._id}`"
                      class="vs-con-loading__container"
                      @click="train(tr)"
                      size="small"
                    >
                      {{
                        lang.clustering.questionsTable.train[languageSelected]
                      }}
                    </vs-button>
                  </transition>
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>

          <vs-pagination
            class="mt-2 float-right"
            :total="Math.ceil(questionsLength / LIMIT)"
            v-model="currentPage"
            @change="handleChangePage"
          />
        </div>
      </vs-col>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

const LIMIT = 10

const STATUS_COLOR = {
  trained: 'success',
  not_trained: 'danger'
}

const STATUS_ICONS = {
  trained: 'icon-check',
  not_trained: 'icon-x'
}

export default {
  name: 'QuestionsTable',
  props: {
    interval: {
      type: Object,
      required: true
    },
    services: {
      type: Array,
      required: true
    },
    platforms: {
      type: Array,
      required: true
    },
    trainerStatus: String
  },
  data() {
    return {
      LIMIT,
      currentPage: 1,
      questionsData: [],
      loading: null,
      tableKey: new Date().toString()
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected']),
    ...mapGetters('clusters', [
      'questions',
      'questionsLength',
      'questionsBotVersions',
      'versionsById'
    ]),
    ...mapGetters('bots', ['getBots'])
  },
  methods: {
    ...mapActions('bots', ['GET_BOTS']),
    ...mapActions('clusters', ['getQuestions', 'setTrain']),
    getStatusColor(status) {
      return STATUS_COLOR[status] || STATUS_COLOR['danger']
    },
    getStatusIcon(status) {
      return STATUS_ICONS[status] || STATUS_ICONS['error']
    },
    getClusterStatus(status) {
      return (
        this.lang.clustering.questionsTable.statusNames[status][
          this.languageSelected
        ] ||
        this.lang.clustering.questionsTable.statusNames['error'][
          this.languageSelected
        ]
      )
    },
    isTrained(tr) {
      return (
        tr.messageTrainer &&
        tr.messageTrainer.status &&
        tr.messageTrainer.status === 'trained' &&
        tr.intents &&
        tr.intents[0] &&
        tr.intents[0].intent
      )
    },
    // getDetectedIntent(tr) {
    //   return tr.intents && tr.intents[0] && tr.intents[0].intent
    //     ? `${
    //         this.lang.clustering.questionsTable.detectedIntent[
    //           this.languageSelected
    //         ]
    //       }: #${tr.intents[0].intent}`
    //     : ''
    // },
    // getCurrentIntent(tr) {
    //   if (tr.messageTrainer && tr.messageTrainer.intent) {
    //     return [{ intent: tr.messageTrainer.intent }]
    //   } else if (tr.intents && tr.intents.length > 0) {
    //     return tr.intents
    //   } else {
    //     return null
    //   }
    // },
    setNewIntent(intention, tr) {
      this.$set(
        tr,
        'newIntent',
        intention.value &&
          intention.value.name &&
          intention.value.name.replace('#', '')
      )
      this.$set(tr, 'enableTrain', !!tr.newIntent && !!tr.newVersion)
    },
    setNewVersion(version, tr) {
      this.$set(tr, 'newVersion', version.value ? version.value._id : null)
      this.$set(tr, 'enableTrain', !!tr.newIntent && !!tr.newVersion)
    },
    async train(tr) {
      const buttonId = '#loadableButton-' + tr._id
      this.$vs.loading({
        background: 'primary',
        color: '#fff',
        container: buttonId,
        scale: 0.45
      })
      try {
        const payload = {
          id: tr._id,
          intent: tr.newIntent,
          versions: [tr.newVersion]
        }
        await this.setTrain(payload)
        this.$vs.notify({
          color: 'success',
          text: this.lang.clustering.training.messages.success[
            this.languageSelected
          ]
        })
        this.$set(tr, 'enableTrain', false)
        this.$set(tr, 'newVersion', [])
        this.$vs.loading.close(buttonId)
        this.load()
      } catch (error) {
        if (error.message !== 'cancelled') console.error(error)
        this.$vs.loading.close(buttonId)
      }
    },
    getQueryParams() {
      const offset = LIMIT * (this.currentPage - 1)
      let queryParam = `limit=${LIMIT}&offset=${offset}`

      const toDateStr =
        this.interval &&
        this.$moment(this.interval.to)
          .format()
          .slice(0, 19)
          .concat('Z')
      const fromDateStr =
        this.interval &&
        this.$moment(this.interval.from)
          .format()
          .slice(0, 19)
          .concat('Z')

      queryParam += toDateStr ? `&where[_createdAt][$lte]=${toDateStr}` : ''
      queryParam += fromDateStr ? `&where[_createdAt][$gte]=${fromDateStr}` : ''

      this.services.forEach(
        botId => (queryParam += `&where[service][$in]=${botId}`)
      )

      this.platforms.forEach(
        platform => (queryParam += `&where[platform][$in]=${platform}`)
      )

      queryParam += this.trainerStatus
        ? `&where[messageTrainer.status][$in]=${this.trainerStatus}`
        : ''
      return queryParam
    },
    async handleChangePage(page) {
      this.currentPage = page
      await this.load()
      this.tableKey = new Date().toString()
    },
    parseQuestions(questions) {
      const parsed = []
      questions.forEach(q =>
        parsed.push({
          ...q,
          enableTrain: false,
          newIntent: null,
          newVersion: null
        })
      )
      return parsed
    },
    async load() {
      const queryParams = this.getQueryParams()
      try {
        // if (!this.questionsData[this.currentPage]) {
        this.$vs.loading()
        this.loading = true

        await this.getQuestions(queryParams)

        // this.questionsData[this.currentPage] = this.parseQuestions(
        //   this.questions
        // )

        this.questionsData = this.parseQuestions(this.questions)

        this.loading = false
        this.$vs.loading.close()
        // }
      } catch (error) {
        if (error.message !== 'cancelled') console.error(error)
        this.loading = false
        this.$vs.loading.close()
      }
    },
    reset() {
      this.currentPage = 1
      this.load()
    },
    getBotName(serviceId) {
      const bot = this.getBots.find(b => b._botService.id === serviceId)
      return bot && bot._botService && bot._botService.name
    }
  },
  components: {
    VsTable: () => import('@/components/vx-table/vsTable'),
    VsTh: () => import('@/components/vx-table/vsTh'),
    VsTr: () => import('@/components/vx-table/vsTr'),
    VsTd: () => import('@/components/vx-table/vsTd'),
    IntentFilter: () => import('../../filters/components/IntentFilter.vue'),
    VersionFilter: () => import('../../filters/components/VersionFilter.vue'),
    KonaHelpTooltip: () => import('@/components/KonaHelpTooltip.vue')
  },
  watch: {
    services() {
      this.reset()
    },
    interval() {
      this.reset()
    },
    platforms() {
      this.reset()
    },
    trainerStatus() {
      this.reset()
    }
  },
  async mounted() {
    await this.GET_BOTS()
    this.load()
  }
}
</script>

<style lang="scss">
.questions-table {
  margin: 30px 0px;
  padding: 20px;
  background-color: #fafafa;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  // TODO: move to src/assets/scss/multi-select.scss
  .multiselect {
    width: 220px;
    &__tags {
      width: unset;
    }
    &__single,
    &__option,
    &__input {
      font-size: 14px;
    }
  }

  .con-vs-chip {
    width: 124px;
  }

  .vs-table--tbody-table td {
    // question
    &:nth-of-type(1) {
      width: 40%;
    }

    // status
    &:nth-of-type(2) {
      width: 6%;
    }

    // bot
    &:nth-of-type(3) {
      width: 10%;
    }

    // question bot version
    &:nth-of-type(4) {
      width: 10%;
    }

    // bot versions to train
    &:nth-of-type(5) {
      width: 10%;
    }

    // toolip with detected intent
    // &:nth-of-type(6) {
    //   width: 2%;
    // }

    // bot intents
    &:nth-of-type(6) {
      width: 12%;
      min-width: 236px;
    }

    // actions
    &:nth-of-type(7) {
      width: 10%;
      min-width: 112px;
    }
  }

  .vs-con-table .vs-con-tbody {
    background: white;
    &::-webkit-scrollbar {
      display: none;
    }
    .vs-table--tbody-table td {
      padding: 8px 8px;
    }
  }
}
</style>
